<template>
  <div class="menu-container">
    <div class="d-none d-lg-flex">
      <menu-desktop
        v-if="isDesktop()"
        :show-notifications.sync="showNotifications"
        @logout="logout()"
        @toggle-notifications="toggleNotificationDialog()"
      />
    </div>
    <div class="d-block d-lg-none mobile">
      <menu-mobile
        v-if="!isDesktop()"
        :show-notifications.sync="showNotifications"
        @logout="logout()"
        @toggle-notifications="toggleNotificationDialog()"
      />
    </div>
    <notification-dialog
      v-if="$can('Ver notificaciones', 'Notificaciones')"
      v-on-clickaway="hideNotifications"
      :show="showNotifications"
    />
  </div>
</template>

<script>
import lazyLoad from "@/app/components/lazyLoad";
import { mixin as clickaway } from "vue-clickaway";
import ScreensMixin from "@/mixins/screen";

/* Lazy loaded components */
const NotificationDialog = () =>
  lazyLoad(import("@/app/views/Notifications/NotificationDesktop.vue"));
const MenuMobile = () => lazyLoad(import("./MenuMobile"));
const MenuDesktop = () => lazyLoad(import("./MenuDesktop"));

export default {
  name: "ZMenu",

  components: {
    NotificationDialog,
    MenuMobile,
    MenuDesktop
  },

  mixins: [clickaway, ScreensMixin],

  data() {
    return {
      showNotifications: false
    };
  },

  methods: {
    hideNotifications($event) {
      const { id } = $event.target;
      const isLogsButton = id === "log";
      if (this.showNotifications && !isLogsButton) {
        this.showNotifications = false;
      }
    },

    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$store.dispatch("cities/resetCitiesState");
        this.$router.push({ name: "login" }).catch(() => {});
      });
    },

    toggleNotificationDialog() {
      this.showNotifications = !this.showNotifications;
    }
  }
};
</script>

<style lang="scss" scoped>
.menu-container {
  z-index: 1000 !important;
}

.mobile {
  position: fixed;
  width: 100%;
  overflow: auto;

  /*
  This is because osm z-index property reach 1000
  and the socket connection view was set to 1001
  */
  z-index: 1002;
  background-color: $charcoal;

  a {
    color: $white !important;
  }

  .navbar {
    padding: 1rem 1rem;
    box-sizing: border-box;
  }
}
</style>
