<template>
  <div class="grid-page">
    <div class="menu">
      <z-menu />
    </div>
    <div class="content">
      <not-found v-if="displayNotFound" />
      <router-view v-else />
    </div>
    <notifications group="notifications" />
  </div>
</template>

<script>
const NotFound = () => import("@/app/views/NotFound.vue");
import ZMenu from "@/app/components/ZMenu/ZMenu";

export default {
  name: "MainLayout",
  components: {
    ZMenu,
    NotFound
  },
  computed: {
    displayNotFound() {
      return this.$store.state.displayNotFound;
    }
  }
};
</script>

<style lang="scss">
.grid-page {
  min-height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 60px repeat(11, 1fr);
  grid-template-rows: 73px 1fr;
}

// Mobile first
.menu {
  grid-column: 1 / -1;
  grid-row: 1 / 2;
  background-color: $black;
}

.content {
  grid-column: 1 / -1;
  grid-row: 2 / -1;
  background-color: $ghost-white;
  position: relative;
  width: 100%;

  .card {
    border: none;
    border-radius: 8px;
    margin: 0;
    border: 1px solid $gray-200;
  }
}

@media only screen and (min-width: 991px) {
  .menu {
    grid-column: 1 / 2;
    grid-row: 1 / -1;
  }

  .content {
    grid-column: 2 / -1;
    grid-row: 1 / -1;

    .card {
      margin: 10px;
    }
  }
}
</style>
